<template>
  <div>
    <hero-bar>
      <span class="capitalize"> {{ title }} </span>
      <router-link v-if="!error" slot="right" to="/users" class="button">
        {{ $getTranslation("general.views.user", 1, true) }}
      </router-link>
    </hero-bar>
    <section class="section is-main-section">
      <card-component icon="account">
        <b-notification
          v-if="error"
          has-text-centered
          type="is-danger"
          aria-close-label="Close notification"
          role="alert"
        >
          {{ getErrors() }}
        </b-notification>

        <ValidationObserver v-else ref="observer" v-slot="{ passes }">
          <form ref="form" @submit.prevent="handleSubmit" noValidate>
            <input
              type="hidden"
              name="authUser"
              v-model="isLoggedUser.userId"
            />
            <InputWithValidation
              horizontal
              rules="required"
              type="name"
              v-model="form.name"
              icon="account"
              :label="$getTranslation('form.name.label')"
              :placeholder="$getTranslation('form.name.placeholder')"
            />

            <InputWithValidation
              horizontal
              rules="required|email"
              type="email"
              v-model="form.email"
              icon="email"
              :label="$getTranslation('form.email.label')"
              :placeholder="$getTranslation('form.email.placeholder')"
              @keyup.native="validateUnique(form.email, 'email', email)"
              provider="provider"
            />

            <hr />

            <h3
              class="title size-4"
              @click="toggle()"
              v-if="
                user !== null &&
                  (isLoggedUser.roles.includes('admin') ||
                    isLoggedUser.roles.includes('owner'))
              "
            >
              {{ $getTranslation("general.views.role", 1, true) }} /
              {{ $getTranslation("general.views.permission", 1, true) }}
              <b-icon icon="plus" type="is-primary"></b-icon>
            </h3>
            <div class="columns is-desktop">
              <div v-if="!isHidden">
                <div class="column" v-if="form.allRoles !== null">
                  <b-field
                    :label="$getTranslation('general.views.role', 1)"
                    class="field has-check"
                    horizontal
                  >
                    <checkbox-picker
                      :options="getCheckboxObject(form.allRoles)"
                      v-model="form.roles"
                      type="is-primary"
                      @checked="selected(...arguments)"
                    />
                  </b-field>
                </div>

                <div class="column" v-if="form.allPermissions !== null">
                  <b-field
                    :label="$getTranslation('general.views.permission', 1)"
                    class="has-check"
                    horizontal
                  >
                    <checkbox-picker
                      :options="getCheckboxObjectDouble(form.allPermissions)"
                      v-model="form.permissions"
                      type="is-primary"
                    />
                  </b-field>
                </div>
              </div>
            </div>

            <div v-if="user !== null && profile">
              <h3 class="title size-4" @click="showProfile = !showProfile">
                {{ $getTranslation("general.views.profile") }}
              </h3>

              <div class="mb-3">
                <h3 class="is-block subtitle">
                  {{ $getTranslation("profile.job-fields") }}
                </h3>
                <div v-if="form.profile.job_start">
                  <div>
                    {{ $getTranslation("profile.change-job-start-date-text") }}
                    {{ form.profile.job_start }}
                  </div>
                  <b-button
                    type="is-primary"
                    @click="form.profile.job_start = null">
                    {{ $getTranslation("profile.change-job-start-date-button") }}
                    </b-button>
                </div>
                <app-dropdown-job
                  :data="{
                    selectedOption: selectedOption,
                    dropdownOptions: jobOptions,
                  }"
                  @onSelected="onSelected"
                  v-else
                />
              </div>
              <hr />

              <ProjectInfo
                :show-version-a="
                  job_start_delta_smaller(formSession.profile.job_start, 180)
                "
                :participate-survey="true"
                :participate-interview="true"
                @change-form-value="changeFormValue"
              />

              <div>
                <hr class="is-dark" />

                <h3 class="subtitle">
                  {{ $getTranslation("profile.general-fields") }}
                </h3>
                <b-field :label="$getTranslation('profile.age.label')">
                  <PreSurveyDropdown class="narrow"
                    @change-dropdown-value="changeFormValue"
                    field-name="age"
                    :max-height="400"
                    :scrollable="true"
                    :selected-option="form.profile.age"
                    :dropdown-options="array_with_extra(15, 67, '>67', '67+')"
                  />
                </b-field>
                <b-field :label="$getTranslation('profile.gender.label')">
                  <PreSurveyDropdown
                    @change-dropdown-value="changeFormValue"
                    field-name="gender"
                    :selected-option="form.profile.gender"
                    :dropdown-options="getOptions($getTranslation('profile.gender.results'))"
                  />
                </b-field>
                <b-field :label="$getTranslation('profile.no-siblings.label')">
                  <PreSurveyDropdown
                    @change-dropdown-value="changeFormValue"
                    field-name="no_siblings"
                    :selected-option="form.profile.no_siblings"
                    :dropdown-options="getOptions($getTranslation('profile.no-siblings.results'))"
                  />
                </b-field>
                <b-field class="narrow" :label="$getTranslation('profile.nationality')">
                  <b-input v-model="form.profile.nationality" />
                </b-field>
                <b-field :label="$getTranslation('profile.no-family.label')">
                  <PreSurveyDropdown
                    @change-dropdown-value="changeFormValue"
                    field-name="no_family"
                    :selected-option="form.profile.no_family"
                    :dropdown-options="getOptions($getTranslation('profile.no-family.results'))"
                  />
                </b-field>

                <h3 class="subtitle">
                  {{ $getTranslation("profile.family-fields") }}
                </h3>
                <b-field :label="$getTranslation('profile.relationship.label')">
                  <PreSurveyDropdown
                    @change-dropdown-value="changeFormValue"
                    field-name="relationship"
                    :selected-option="form.profile.relationship"
                    :dropdown-options="getOptions($getTranslation('profile.relationship.results'))"
                  />
                </b-field>
                <b-field :label="$getTranslation('profile.no-children.label')">
                  <PreSurveyDropdown
                    @change-dropdown-value="changeFormValue"
                    field-name="no_children"
                    :selected-option="form.profile.no_children"
                    :dropdown-options="getOptions($getTranslation('profile.no-children.results'))"
                  />
                </b-field>

                <b-field
                  :label="$getTranslation('profile.partner-job.label')"
                  v-if="showFilter.show_ind_partn"
                >
                  <PreSurveyDropdown
                    @change-dropdown-value="changeFormValue"
                    field-name="partner_job_status"
                    :selected-option="form.profile.partner_job_status"
                    :dropdown-options="getOptions($getTranslation('profile.partner-job.results'))"
                  />
                </b-field>

                <h3 class="subtitle">
                  {{ $getTranslation("profile.education-fields") }}
                </h3>
                <b-field :label="$getTranslation('profile.education.label')">
                  <PreSurveyDropdown
                    @change-dropdown-value="changeFormValue"
                    field-name="education"
                    :selected-option="form.profile.education"
                    :dropdown-options="getOptions($getTranslation('profile.education.results'))"
                  />
                </b-field>
                <b-field
                  class="narrow"
                  :label="$getTranslation('profile.completed-training.label')"
                >
                  <b-input v-model="form.profile.completed_training" />
                </b-field>
                <b-field
                  :label="$getTranslation('profile.qualifications.label')"
                >
                  <b-input v-model="form.profile.qualifications" />
                </b-field>
              </div>
              <!-- end show profile -->
            </div>
            <hr />

            <div v-if="showFilter.show_post_job_start">
                <h3 class="subtitle">
                  {{ $getTranslation("profile.job-fields") }}
                </h3>
                <b-field :label="$getTranslation('profile.employment.label')">
                  <PreSurveyDropdown
                    @change-dropdown-value="changeFormValue"
                    field-name="employment_years"
                    :max-height="400"
                    :scrollable="true"
                    :selected-option="form.profile.employment_years"
                    :dropdown-options="array_with_extra(1970, 2021)"
                  />
                </b-field>

                <b-field class="narrow" :label="$getTranslation('profile.employers.label')">
                  <b-input v-model="form.profile.employers" type="number" />
                </b-field>

                <b-field
                  :label="$getTranslation('profile.employment-years.label')"
                >
                  <PreSurveyDropdown
                    @change-dropdown-value="changeFormValue"
                    field-name="employment_years"
                    :max-height="400"
                    :scrollable="true"
                    :selected-option="form.profile.employment_years"
                    :dropdown-options="array_with_extra(0, 50)"
                  />
                </b-field>

                <b-field
                  :label="$getTranslation('profile.employment-sector.label')"
                >
                  <PreSurveyDropdown
                    @change-dropdown-value="changeFormValue"
                    :max-height="400"
                    :scrollable="true"
                    field-name="employment_sector"
                    :selected-option="form.profile.employment_sector"
                    :dropdown-options="getOptions($getTranslation('profile.employment-sector.results'))"
                  />
                </b-field>
                <div v-if="showFilter.show_un_bran_add" class="mb-4">
                  {{ $getTranslation("profile.un_bran_add.label") }}
                  <b-input v-model="form.profile.un_bran_add" />
                </div>

                <b-field
                  :label="$getTranslation('profile.employees-range.label')"
                >
                  <PreSurveyDropdown
                    @change-dropdown-value="changeFormValue"
                    field-name="employees_no_range"
                    :selected-option="form.profile.employees_no_range"
                    :dropdown-options="getOptions($getTranslation('profile.employees-range.results'))"
                  />
                </b-field>

                <b-field
                  :label="$getTranslation('profile.company-range.label')"
                >
                  <LikertScale
                    field-name="company_stars"
                    :min-label="
                      $getTranslation('profile.company-range.min-label')
                    "
                    :max-label="
                      $getTranslation('profile.company-range.max-label')
                    "
                    :num-items="
                      $getTranslation('profile.company-range.num-items')
                    "
                    @change-likert-value="changeFormValue"
                    :selected-item="form.profile.company_stars"
                  />
                </b-field>

                <b-field
                  :label="$getTranslation('profile.profit-organization.label')"
                >
                  <PreSurveyDropdown
                    @change-dropdown-value="changeFormValue"
                    field-name="profit_oriented"
                    :selected-option="form.profile.profit_oriented"
                    :dropdown-options="getOptions($getTranslation('profile.profit-organization.results'))"
                  />
                </b-field>

                <b-field
                  :label="$getTranslation('profile.employment-status.label')"
                >
                  <PreSurveyDropdown
                    @change-dropdown-value="changeFormValue"
                    field-name="employment_status"
                    :selected-option="form.profile.employment_status"
                    :dropdown-options="getOptions($getTranslation('profile.employment-status.results'))"
                  />
                </b-field>
                <div v-if="showFilter.show_un_ret_add" class="mb-4">
                  {{ $getTranslation("profile.un_ret_add.label") }}
                  <b-input v-model="form.profile.un_ret_add" />
                </div>

                <b-field
                  :label="$getTranslation('profile.company-existence.label')"
                >
                  <PreSurveyDropdown
                    @change-dropdown-value="changeFormValue"
                    field-name="company_start"
                    :selected-option="form.profile.company_start"
                    :dropdown-options="getOptions($getTranslation('profile.company-existence.results'))"
                  />
                </b-field>
                <b-field
                  :label="$getTranslation('profile.company-website.label')"
                >
                  <b-input v-model="form.profile.company_website" />
                </b-field>

              <!-- <div class="column"> -->
                <h3 class="subtitle">
                  {{ $getTranslation("profile.current-job-fields") }}
                </h3>
                <b-field :label="$getTranslation('profile.job-title.label')">
                  <b-input v-model="form.profile.job_title" />
                </b-field>
                <b-field
                  :label="
                    $getTranslation('profile.contract-working-hours.label')
                  "
                >
                  <PreSurveyDropdown
                    @change-dropdown-value="changeFormValue"
                    field-name="contract_working_hours"
                    :scrollable="true"
                    :max-height="400"
                    :selected-option="form.profile.contract_working_hours"
                    :dropdown-options="
                      Array.from(Array(49).keys()).map((k) => {
                        return { value: k, label: k.toString() };
                      })
                    "
                  />
                </b-field>
                <b-field
                  :label="$getTranslation('profile.actual-working-hours.label')"
                >
                  <PreSurveyDropdown
                    @change-dropdown-value="changeFormValue"
                    field-name="actual_working_hours"
                    :scrollable="true"
                    :max-height="400"
                    :selected-option="form.profile.actual_working_hours"
                    :dropdown-options="array_with_extra(1, 80, '> 80', '80+')"
                  />
                </b-field>
                <b-field :label="$getTranslation('profile.working-days.label')">
                  <PreSurveyDropdown
                    @change-dropdown-value="changeFormValue"
                    field-name="working_days"
                    :selected-option="form.profile.working_days"
                    :dropdown-options="getOptions($getTranslation('profile.working-days.results'))"
                  />
                </b-field>
                <b-field
                  :label="$getTranslation('profile.home-working-days.label')"
                >
                  <PreSurveyDropdown
                    @change-dropdown-value="changeFormValue"
                    field-name="home_working_days"
                    :selected-option="form.profile.home_working_days"
                    :dropdown-options="getOptions($getTranslation('profile.home-working-days.results'))"
                  />
                </b-field>
                <b-field
                  :label="$getTranslation('profile.employment-country.label')"
                >
                  <b-input v-model="form.profile.employment_country" />
                </b-field>
                <b-field
                  :label="$getTranslation('profile.job-experience.label')"
                >
                  <PreSurveyDropdown
                    @change-dropdown-value="changeFormValue"
                    field-name="job_experience"
                    :selected-option="form.profile.job_experience"
                    :dropdown-options="getOptions($getTranslation('profile.job-experience.results'))"
                  />
                </b-field>
                <b-field
                  :label="$getTranslation('profile.job-client-contacts.label')"
                >
                  <PreSurveyDropdown
                    @change-dropdown-value="changeFormValue"
                    field-name="job_client_contacts"
                    :selected-option="form.profile.job_client_contacts"
                    :dropdown-options="getOptions($getTranslation('profile.job-client-contacts.results'))"
                  />
                </b-field>
                <b-field :label="$getTranslation('profile.job-salary.label')">
                  <PreSurveyDropdown
                    @change-dropdown-value="changeFormValue"
                    field-name="job_salary"
                    :selected-option="form.profile.job_salary"
                    :dropdown-options="getOptions($getTranslation('profile.job-salary.results'))"
                  />
                </b-field>
                <b-field
                  :label="$getTranslation('profile.job-responsibility.label')"
                >
                  <PreSurveyDropdown
                    @change-dropdown-value="changeFormValue"
                    field-name="job_responsibility"
                    :selected-option="form.profile.job_responsibility"
                    :dropdown-options="getOptions($getTranslation('profile.job-responsibility.results'))"
                  />
                </b-field>
                <div v-if="showFilter.show_jo_resp_add" class="mb-4">
                  {{ $getTranslation("profile.job-responsibility-no.label") }}
                  <b-input v-model="form.profile.job_responsibility_number" />
                </div>

                <h3 class="subtitle">
                  {{ $getTranslation("profile.job-environment-fields") }}
                </h3>
                <b-field :label="$getTranslation('profile.job-team.label')">
                  <PreSurveyDropdown
                    @change-dropdown-value="changeFormValue"
                    field-name="job_team"
                    :selected-option="form.profile.job_team"
                    :dropdown-options="getOptions($getTranslation('profile.job-team.results'))"
                  />
                </b-field>
                <b-field
                  :label="$getTranslation('profile.team-no.label')"
                  v-if="showFilter.show_team_items"
                >
                  <PreSurveyDropdown
                    @change-dropdown-value="changeFormValue"
                    field-name="job_team_no"
                    :scrollable="true"
                    :max-height="400"
                    :selected-option="form.profile.job_team_no"
                    :dropdown-options="array_with_extra(2, 35, '>35', '35+')"
                  />
                </b-field>
                <b-field
                  :label="$getTranslation('profile.team-interaction.label')"
                  v-if="showFilter.show_team_items"
                >
                  <PreSurveyDropdown
                    @change-dropdown-value="changeFormValue"
                    field-name="job_team_interaction"
                    :selected-option="form.profile.job_team_interaction"
                    :dropdown-options="getOptions($getTranslation('profile.team-interaction.results'))"
                  />
                </b-field>
                <b-field
                  :label="$getTranslation('profile.team-sync.label')"
                  v-if="showFilter.show_team_items"
                >
                  <LikertScale
                    field-name="job_team_sync"
                    :min-label="$getTranslation('profile.team-sync.min-label')"
                    :max-label="$getTranslation('profile.team-sync.max-label')"
                    :num-items="$getTranslation('profile.team-sync.num-items')"
                    @change-likert-value="changeFormValue"
                    :selected-item="form.profile.job_team_sync"
                  />
                </b-field>
                <b-field
                  :label="$getTranslation('profile.team-virtual.label')"
                  v-if="showFilter.show_team_items"
                >
                  <LikertScale
                    field-name="job_team_virtual"
                    :min-label="
                      $getTranslation('profile.team-virtual.min-label')
                    "
                    :max-label="
                      $getTranslation('profile.team-virtual.max-label')
                    "
                    :num-items="
                      $getTranslation('profile.team-virtual.num-items')
                    "
                    @change-likert-value="changeFormValue"
                    :selected-item="form.profile.job_team_virtual"
                  />
                </b-field>
                <b-field
                  :label="$getTranslation('profile.team-spatial.label')"
                  v-if="showFilter.show_team_items"
                >
                  <LikertScale
                    field-name="job_team_spatial"
                    :min-label="
                      $getTranslation('profile.team-spatial.min-label')
                    "
                    :max-label="
                      $getTranslation('profile.team-spatial.max-label')
                    "
                    :num-items="
                      $getTranslation('profile.team-spatial.num-items')
                    "
                    @change-likert-value="changeFormValue"
                    :selected-item="form.profile.job_team_spatial"
                  />
                </b-field>
                <b-field :label="$getTranslation('profile.job-leader.label')">
                  <PreSurveyDropdown
                    @change-dropdown-value="changeFormValue"
                    field-name="job_leader"
                    :selected-option="form.profile.job_leader"
                    :dropdown-options="
                      Object.keys(
                        $getTranslation('profile.job-leader.results')
                      ).map((k) => {
                        return {
                          value: k,
                          label: $getTranslation('profile.job-leader.results')[
                            k
                          ],
                        };
                      })
                    "
                  />
                </b-field>
                <b-field
                  :label="$getTranslation('profile.leader-gender.label')"
                >
                  <PreSurveyDropdown
                    @change-dropdown-value="changeFormValue"
                    field-name="job_leader_gender"
                    :selected-option="form.profile.job_leader_gender"
                    :dropdown-options="getOptions($getTranslation('profile.leader-gender.results'))"
                  />
                </b-field>
                <b-field
                  class="narrow"
                  :label="$getTranslation('profile.leader-job-years.label')"
                >
                  <b-input
                    v-model="form.profile.job_leader_years"
                    type="number"
                  />
                </b-field>
                <b-field
                  :label="$getTranslation('profile.leader-interaction.label')"
                >
                  <PreSurveyDropdown
                    @change-dropdown-value="changeFormValue"
                    field-name="job_leader_interaction"
                    :selected-option="form.profile.job_leader_interaction"
                    :dropdown-options="getOptions($getTranslation('profile.leader-interaction.results'))"
                  />
                </b-field>
                <b-field :label="$getTranslation('profile.job-covid.label')">
                  <LikertScale
                    field-name="job_covid"
                    :min-label="$getTranslation('profile.job-covid.min-label')"
                    :max-label="$getTranslation('profile.job-covid.max-label')"
                    :num-items="$getTranslation('profile.job-covid.num-items')"
                    @change-likert-value="changeFormValue"
                    :selected-item="form.profile.job_covid"
                  />
                </b-field>
            </div>

            <b-field horizontal>
              <b-field grouped>
                <div class="control">
                  <b-button
                    native-type="submit"
                    type="is-primary"
                    @click="passes(submit)"
                    >{{ $getTranslation("form.submit") }}</b-button
                  >
                </div>
                <div class="control" v-if="user !== null && profile">
                  <b-button type="is-primary is-outlined" @click="reset">{{
                    $getTranslation("form.reset")
                  }}</b-button>
                </div>
              </b-field>
            </b-field>
          </form>
        </ValidationObserver>
      </card-component>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import { ApiUsers } from '@/api-routes.js'
import FormTemplateMixin from '@/mixins/FormTemplateMixin'
import Dropdown from '@/views/invitations/JobDropdown'
import PreSurveyDropdown from '@/components/tum/PreSurveyDropdown'
import LikertScale from '@/components/tum/LikertScale'
import ProjectInfo from '@/components/tum/ProjectInfo'

export default {
  name: 'app-create-user',
  props: ['slug'],
  mixins: [FormTemplateMixin],
  components: {
    ProjectInfo,
    LikertScale,
    PreSurveyDropdown,
    'app-dropdown-job': Dropdown
  },
  data () {
    return {
      title: this.$getTranslation('profile.new'),
      endpoint: ApiUsers,
      email: null,
      // TODO: check when to display profile
      profile: true,
      showProfile: false,
      newPermissions: false,
      formSession: {
        name: null,
        email: null,
        slug: null,
        allRoles: null,
        allPermissions: null,
        userRole: null,
        authUser: this.isLoggedUser? this.isLoggedUser.slug : null,
        // Profile
        profile: {
          salutation: null,
          company: null,
          firstname: null,
          lastname: null,
          address_line_1: null,
          address_line_2: null,
          city: null,
          state: null,
          country: 'DE',
          phone: null,
          mobile: null,
          fax: null,
          email: null,
          web: null,
          description: null,
          interests: null,
          //------
          job_status: null,
          job_start: null,
          gender: null,
          // birthdate: null,
          // birthyear: null,
          nationality: null,
          no_siblings: null,
          relationship: null,
          partner_job_status: null,
          no_children: null,
          no_family: null,
          //------
          age:null,
          education:null,
          completed_training:null,
          employment_company_no:null,
          first_employment:null,
          employment_years:null,
          qualifications:null,
          employment_sector:null,
          other_employment_sector:null,
          employees_no_range:null,
          company_stars:null,
          company_website:null,
          job_title:null,
          contract_working_hours:null,
          actual_working_hours:null,
          working_days:null,
          home_working_days:null,
          employment_country:null,
          job_experience:null,
          job_client_contacts:null,
          job_salary:null,
          job_responsibility:null,
          job_responsibility_number:null,
          job_team:null,
          job_team_no:null,
          job_team_interaction:null,
          job_team_sync:null,
          job_team_virtual:null,
          job_team_spatial:null,
          job_leader:null,
          job_leader_gender:null,
          job_leader_years:null,
          job_leader_interaction:null,
          job_covid:null,
          profit_oriented:null,
          employment_status:null,
          company_start:null,
          un_bran_add: null,
          un_ret_add: null,
          participate_survey: null,
          participate_interview: null
        }
      },
      type: 'is-static',

    }
  },

  computed: {
    ...mapGetters([
      'user',
      'error'
    ]),

    showFilter () {
      return {
        show_ind_partn: this.form.profile.relationship !== "S" && this.form.profile.relationship !== "W",
        show_post_job_start: this.job_start_delta(this.form.profile.job_start, 60),
        show_un_bran_add: this.form.profile.employment_sector === "8" || this.form.profile.employment_sector === 8,
        show_un_ret_add: this.form.profile.employment_status === "1" || this.form.profile.employment_status === 1,
        show_jo_resp_add: this.form.profile.job_responsibility === "1" || this.form.profile.job_responsibility === 1,
        show_team_items: this.form.profile.job_team === "1" || this.form.profile.job_team === 1,
      }
    },

    selectedOption () {
      return {value: 0, text: this.$getTranslation('profile.job-options')[0]["0"]}
      },

    jobOptions() {
      return [
        { value: 'Y', text: this.$getTranslation('profile.job-options')[1]["Y"] },
        { value: 'N', text: this.$getTranslation('profile.job-options')[2]["N"] },
        { value: 'M', text: this.$getTranslation('profile.job-options')[3]["M"] }
      ]
    },

    form: {
      get: function () {
        let form = {};

        // form is empty if user is created, has props from formSession
        form = this.formSession

        // On create user, slug is not defined, form is empty
        if (typeof this.slug === "undefined" || this.slug === null) {
          form.authUser = this.isLoggedUser ? this.isLoggedUser.slug : null
          // this.formSession = form
          return form
        }

        // Assign data from backend to the form, and save values in formSession
        // for reset action
        this.setFormValues (form)
        // console.log(form)
        // TODO: only admins and owner might see roles and permissions
        /*if(this.isLoggedUser.roles !== 'admin' || this.isLoggedUser.roles !== 'owner') {
          form.allRoles = null
          form.allPermissions = null
        }*/
        return form
      },
      // setter needed to reset the form
      set: function(form) {
        this.setFormValues (form)
      }
    }
  },

  methods: {
    ...mapActions([
      "signUserUp",
      "fetchUser",
      "resetUser",
    ]),

    job_start_delta(job_start, delta) {
      // This will calculate the number of days between now and job_start and return true
      // if that value is more than the passed delta. If job_start is null it returns false.
      // the magic number 86400000 is the number of milliseconds in a day.
      if (!job_start) {
        return false
      }

      const dt = new Date(job_start)
      const today = new Date()
        return Math.ceil(Math.abs((today - dt) / 86400000)) > delta
      /*if(typeof smaller !== 'undefined' && smaller) {
        return Math.ceil(Math.abs((today - dt) / 86400000)) < delta
      }*/

    },
// Not in use
    job_start_delta_smaller(job_start, delta) {
      // This will calculate the number of days between now and job_start and return true
      // if that value is more than the passed delta. If job_start is null it returns false.
      // the magic number 86400000 is the number of milliseconds in a day.
      if (!job_start) {
        return false
      }
      const dt = new Date(job_start)
      const today = new Date()
      return Math.ceil(Math.abs((today - dt) / 86400000)) < delta
    },

    array_with_extra(n_first, n_last, extra_label, extra_value) {
      let res = []
      for (let i = n_first; i <= n_last; i++){
        res.push({value: i.toString(), label: i.toString()})
      }
      if (extra_label) {
        res.push({ label: extra_label, value: extra_value })
      }
      return res
    },

    /**
     * read options from profile.json and send an array of items
     */
    getOptions (array) {
      let options = []
        Object.keys(array).map((k) => {
          options.push({
            value: k,
            label: array[k],
          })
        })
      return options
    },

    selected(...args) {
      const [role, selected] = args
      // console.log('checked? ', args, role, selected)
      // @checked="checked(...arguments)" or @checked="checked"
      Object.values(this.form.allRoles).map(item => {
        // console.log('item name: ', item.name, role)
        if(item.slug === role) {
          let permissions = Object.values(item.permissions);
          permissions.map(permission => {
            // console.log('role item: ', permission.slug)
            if(typeof(selected) !== "undefined" && selected) {
              this.form.permissions.push(permission.slug)
            } else {
              let index = this.form.permissions.indexOf(permission.slug)
              if(index !== -1) {
                this.form.permissions.splice(index, 1)
              }
            }
          })
        }
      })
    },

    changeFormValue(data) {
      this.form.profile[data.field] = data.value
    },

    onSelected(value) {
      this.form.profile.job_status = value.status
      // this.form.profile.job_start = value.date
      this.dateConvert(value.date)
      // this.clearError()
      // console.log(this.form, value.date)
    },

    dateConvert(date) {
      // console.log('date: ', date)
      // TODO create helper function for parsing date !!!

        // let converted = date.getFullYear()+'-'+ String(date.getMonth()+1).padStart(2, '0') +'-'+ String(date.getDate()).padStart(2, '0');
        this.form.profile.job_start = date

    },

    datepickerConvert(date) {
      if (!date) {
        return 'U'
      }
      let converted = moment(date, 'YYYY-MM-DD H:I:S').format('M/D/YYYY')
      // console.log(converted)

      return converted
    },

    reset () {
      this.form = this.formSession
    },

    setFormValues (form) {
      // console.log('set form values: ', this.user)
      if(this.user !== null) {
        form.name = this.user.name
        form.slug = this.user.slug
        this.email = form.email = this.user.email

        let roles = [];
        let permissions = [];
        if (this.user.allRoles !== null) {
          form.allRoles = this.user.allRoles
        }
        if (this.user.allPermissions !== null) {
          form.allPermissions = this.user.allPermissions
          // console.log('all permissions: ', form.allPermissions)
        }

        if (this.user.roles !== null) {
          form.roles = this.buildArray(this.user.roles)
        }
        if (this.user.permissions !== null) {
          form.permissions = this.buildArray(this.user.permissions)
        }

        if(this.user.profile !== null) {
          this.profile = true
          let profile = this.user.profile
          // console.log('profile: ', profile)
          // let profile = this.user.profile
          // Profile
          form.profile = {
            salutation: profile.salutation,
            company: profile.company,
            firstname: profile.firstname,
            lastname: profile.lastname,
            address_line_1: profile.address_line_1,
            address_line_2: profile.address_line_2,
            city: profile.city,
            state: profile.state,
            country: profile.country,
            phone: profile.phone,
            mobile: profile.mobile,
            fax: profile.fax,
            email: profile.email,
            web: profile.web,
            description: profile.description,
            interests: profile.interests,
            job_status: profile.job_status,
            job_start: this.datepickerConvert(profile.job_start),
            gender: profile.gender,
            // birthdate: profile.birthdate,
            // birthyear: profile.birthyear,
            nationality: profile.nationality === "U" ? "" : profile.nationality,
            no_siblings: profile.no_siblings,
            relationship: profile.relationship,
            partner_job_status: profile.partner_job_status,
            no_children: profile.no_children,
            no_family: profile.no_family,
            age: profile.age,
            education: profile.education,
            completed_training: profile.completed_training === "U" ? "" : profile.completed_training,
            employment_company_no: profile.employment_company_no,
            first_employment: profile.first_employment,
            employment_years: profile.employment_years,
            qualifications: profile.qualifications === "U" ? "" : profile.qualifications,
            employment_sector: profile.employment_sector === "U" ? "" : profile.employment_sector,
            other_employment_sector: profile.other_employment_sector === "U" ? "" : profile.other_employment_sector,
            employees_no_range: profile.employees_no_range,
            company_stars: profile.company_stars,
            company_website:profile.company_website === "U" ? "" : profile.company_website,
            job_title: profile.job_title === "U" ? "" : profile.job_title,
            contract_working_hours: profile.contract_working_hours,
            actual_working_hours: profile.actual_working_hours,
            working_days:profile.working_days,
            home_working_days:profile.home_working_days,
            employment_country: profile.employment_country === "U" ? "" : profile.employment_country,
            job_experience: profile.job_experience,
            job_client_contacts: profile.job_client_contacts,
            job_salary: profile.job_salary,
            job_responsibility: profile.job_responsibility,
            job_responsibility_number: profile.job_responsibility_number,
            job_team: profile.job_team,
            job_team_no: profile.job_team_no,
            job_team_interaction: profile.job_team_interaction,
            job_team_sync: profile.job_team_sync,
            job_team_virtual: profile.job_team_virtual,
            job_team_spatial: profile.job_team_spatial,
            job_leader: profile.job_leader,
            job_leader_gender: profile.job_leader_gender === "U" ? "" : profile.job_leader_gender,
            job_leader_years: profile.job_leader_years,
            job_leader_interaction: profile.job_leader_interaction,
            job_covid: profile.job_covid,
            profit_oriented: profile.profit_oriented,
            employment_status: profile.employment_status,
            company_start: profile.company_start,
            un_bran_add: profile.un_bran_add,
            un_ret_add: profile.un_ret_add,
            participate_survey: profile.participate_survey,
            participate_interview: profile.participate_interview
          }
        } else {
          // empty fields
          form.profile = {
            salutation: null,
            company: null,
            firstname: null,
            lastname: null,
            address_line_1: null,
            address_line_2: null,
            city: null,
            state: null,
            country: null,
            phone: null,
            mobile: null,
            fax: null,
            email: null,
            web: null,
            description: null,
            interests: null,
            job_status: null,
            job_start: null,
            gender: null,
            // birthdate: null,
            // birthyear: null,
            nationality: null,
            no_siblings: null,
            relationship: null,
            partner_job_status: null,
            no_children: null,
            no_family: null,
            age:null,
            education:null,
            completed_training:null,
            employment_company_no:null,
            first_employment:null,
            employment_years:null,
            qualifications:null,
            employment_sector:null,
            other_employment_sector:null,
            employees_no_range:null,
            company_stars:null,
            company_website:null,
            job_title:null,
            contract_working_hours:null,
            actual_working_hours:null,
            working_days:null,
            home_working_days:null,
            employment_country:null,
            job_experience:null,
            job_client_contacts:null,
            job_salary:null,
            job_responsibility:null,
            job_responsibility_number:null,
            job_team:null,
            job_team_no:null,
            job_team_interaction:null,
            job_team_sync:null,
            job_team_virtual:null,
            job_team_spatial:null,
            job_leader:null,
            job_leader_gender:null,
            job_leader_years:null,
            job_leader_interaction:null,
            job_covid:null,
            profit_oriented:null,
            employment_status:null,
            company_start:null,
            un_bran_add: null,
            un_ret_add: null,
            participate_survey: null,
            participate_interview: null
          }
        }
        this.formSession = form
      }
    },

    fetchData (item) {
      let data = {}
      let form = this.form
      // console.log(typeof form.profile.job_start, form.profile.job_start)
      if (form.profile.job_start == 'U') {
        // console.log('UNDEFINED')
      } else {
        form.profile.job_start = moment(form.profile.job_start, 'M/D/YYYY').format('YYYY-MM-DD')
      }

      // set property defined in the form
      for (let property in form) {
        // console.log('prop in a form: ', property)
        data[property] = form[property]
      }

      return data
    },
  },

  mounted () {
    this.moduleName = this.$route.meta.type

    // create user - form should be empty
    // checking another user and then trying to create user, data of
    // previus user are still present
    // TODO: check all situations where user's data might present
    // ie on each change reset data, to not carring them around
    if(this.$route.name === 'users.create') {
      this.resetUser()
      return
    }

    let slug = ''
    /*if(this.$route.name === 'profile') {
      slug = this.isLoggedUser.slug
    }*/
    if(typeof(this.slug) !== 'undefined' && this.slug.length || slug !== '') {
      this.title = this.$getTranslation('profile.edit')
      let userSlug = this.slug
      if(slug !== '') {
        userSlug = slug
      }

      // All users can edit theirs profile
      if(this.isLoggedUser.slug === userSlug) {
        userSlug = 'profile'
      }
      // console.log('slug: ', userSlug)
      this.fetchUser(userSlug)

      this.formMethod = 'put'
    }
  }
}
</script>

<style>
  .narrow input.input {
    width: 200px !important;
  }

</style>
