<template>

    <b-dropdown
      :triggers="['hover']"
      @change="changeValue($event)"
      v-model="localSelectedOption"
      :scrollable="scrollable"
      :max-height="maxHeight"
      aria-role="list">
      <template #trigger>
        <b-button
            v-model="localSelectedOption"
            :label="getLabel(selectedOption)"
            type="default"
            icon-right="menu-down"/>
      </template>

      <b-dropdown-item
        aria-role="listitem"
        v-for="option in dropdownOptions" :value="option.value" :key="option.label">
          {{ option.label }}
      </b-dropdown-item>

    </b-dropdown>

</template>
<script>
export default {
  name: 'PreSurveyDropdown',
  props: {
    fieldName: {
      // type: String,
      default: null
    },
    selectedOption: {
      // type: String,
      default: null
    },
    dropdownOptions: {
      type: Array
    },
    scrollable: {
      type: Boolean,
      default: false
    },
    maxHeight: {
      type: Number,
      default: 400
    }
  },
  computed: {
    localSelectedOption: {
      get () {
        return this.selectedOption
      },
      set () {
        // setting is done by parent, this is only here to avoid errors in the console
      }
    }
  },
  methods: {
    changeValue(event) {
      this.$emit('change-dropdown-value', {field: this.fieldName, value: event})
    },
    getLabel(value) {
      if (value) {
        for (const option of this.dropdownOptions) {
          if (option.value.toString() === value.toString()) {
            return option.label;
          }
        }
      }
      return this.$getTranslation('form.general.select')
    }
  }
}
</script>
