<template>
  <div class="scale-alignment py-5">
    <span class="is-inline-block" >{{ minLabel }}</span>
    <span class="has-text-centered is-inline-block radio-alignment">
      <span class="columns">
        <span class="column is-one-fifth"  v-for="k in getLabelsValues()" >
          <b-radio
            v-model="localSelectedItem"
            :key="k.value"
            :native-value="k.value">
          </b-radio>
        </span>
      </span>
    </span>
    <span class="is-inline-block" >{{ maxLabel }}</span>
  </div>
</template>

<script>
export default {
  name: 'LikertScale',
  props: {
    numItems: {
      default: 5
    },
    minLabel: {
      default: ''
    },
    maxLabel: {
      default: ''
    },
    selectedItem: {
      default: null
    },
    fieldName: {
      // type: String,
      default: null
    },
  },
  computed: {
    localSelectedItem: {
      get () {
        return this.selectedItem
      },
      set (newValue) {
        this.$emit('change-likert-value', { field: this.fieldName, value: newValue })
      }
    }
  },
  methods: {
    getLabelsValues () {
      let min = 1
      let max = 5
      if (typeof this.numItems === 'string') {
        max = parseInt(this.numItems)
      } else {
        max = this.numItems
      }
      let res = [{ label: this.minLabel, value: min }]
      for (let i = min + 1; i <= max - 1; i++) {
        res.push({ label: '', value: i })
      }
      res.push({ label: this.maxLabel, value: max })
      return res
    }
  }
}
</script>

<style scoped>
  .radio-alignment {
    min-width: 250px;
    max-width: 300px;
    margin: 0 0 0 20px;
  }
  .scale-alignment {
    width: auto;
    min-width: 400px;
    max-width: 750px;
  }
</style>
