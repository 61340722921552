<template>
  <div>
    <div v-if="$i18n.locale.includes('de')">
      <div v-if="showVersionA">
        <!--   PROJEKTABLAUF A   -->
        <b-message>
          <p class="mb-3"><b>Hier die wichtigsten Informationen zum Projektablauf:</b></p>

          <p class="mb-3">
            Wir möchten Sie in den kommenden 3 Jahren auf Ihrem Weg begleiten. Hierfür werden wir Sie im Abstand von 4
            bis 6 Monaten zur Teilnahme an einer <b>Befragung</b> einladen. Die Befragung wird online durchgeführt und
            Sie können bequem von einem mobilen Endgerät (z. B. Smartphone, Tablet) aus teilnehmen. Sie erhalten per
            E-Mail eine Einladung sowie eine Erinnerung an die Befragung. </p>
          <p class="mb-3">
            Ein weiterer Baustein des Forschungsprojekts sind vertiefende <b>Interviews</b>, in denen Sie persönlich von
            Ihren beruflichen Erfahrungen berichten können. Hierfür würden wir Sie ebenfalls alle 4 bis 6 Monate für ein
            Interview kontaktieren. Je nach Rahmenbedingung kann das Interview persönlich oder digital stattfinden. </p>
          <p class="mb-3">
            Neben Ihrem Beitrag zur Wissenschaft profitieren Sie von der Möglichkeit, Ihre persönliche Entwicklung im
            Berufskontext zu reflektieren sowie von individuellen Rückmeldungen aus dem Forschungsprojekt. Außerdem
            erhalten Sie für die aufgewendete Zeit eine finanzielle Aufwandsentschädigung. </p>
          <p class="mb-3">
            Sie können nun entscheiden, ob Sie an der <b>Online-Befragung und / oder den Interviews</b>
            teilnehmen möchten.
          </p>
          <p class="mb-1">
            Wenn Sie an den Online-Befragungen teilnehmen möchten, wählen Sie bitte dieses Kästchen aus:
            <b-checkbox v-model="localParticipateInterview"/>
          </p>
          <p class="mb-3">
            Wenn Sie an den Interviews teilnehmen möchten, wählen Sie bitte dieses Kästchen aus:
            <b-checkbox v-model="localParticipateSurvey"/>
          </p>
        </b-message>
      </div>
      <div v-else>
        <!--   PROJEKTABLAUF B   -->
        <b-message>
          <p class="mb-3"><b>Hier die wichtigsten Informationen zum Projektablauf:</b></p>
          <p class="mb-3">
            Wir möchten Sie in den kommenden 3 Jahren auf Ihrem Weg begleiten. Hierfür werden wir Sie im Abstand von 4
            bis
            6
            Monaten zur Teilnahme an einer Befragung einladen. Die Befragung wird online durchgeführt und Sie können
            bequem von einem mobilen Endgerät (z. B. Smartphone, Tablet) aus teilnehmen. Sie erhalten per E-Mail eine
            Einladung sowie eine Erinnerung an die Befragung.</p>
          <p class="mb-3">
            Neben Ihrem Beitrag zur Wissenschaft profitieren Sie von der Möglichkeit, Ihre persönliche Entwicklung im
            Berufskontext zu reflektieren sowie von individuellen Rückmeldungen aus dem Forschungsprojekt. Außerdem
            erhalten Sie für die aufgewendete Zeit eine finanzielle Aufwandsentschädigung.</p>
        </b-message>
      </div>
    </div>
    <div v-else>
      <b-message>

        <p class="mb-3"><b>Here you will find the most important information about the research process:</b></p>

        <p class="mb-3">
          We would like to accompany on your way for the next three years. For this purpose, we will invite you to
          participate in a <b>survey</b> at intervals of 4 to 6 months. The survey is conducted online and you can
          participate conveniently from a mobile device (e.g., smartphone, tablet). You will receive an email invitation
          for each survey as well as a reminder of the survey.
        </p>
        <p class="mb-3">
          In addition to your contribution to science, you will benefit from the opportunity to reflect on your personal
          development in a professional context as well as from individual feedback from the research project. You will
          also receive a financial incentive for the time spent.
        </p>
      </b-message>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProjectInfo',
  props: {
    showVersionA: {
      type: Boolean,
      default: true
    },
    participateSurvey: {
      type: Boolean,
      default: false
    },
    participateInterview: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    localParticipateSurvey: {
      get () {
        return this.participateSurvey
      },
      set (newValue) {
        this.$emit('change-form-value', {field: 'participate_survey', value: newValue})
      }
    },
    localParticipateInterview: {
      get () {
        return this.participateInterview
      },
      set (newValue) {
        this.$emit('change-form-value', {field: 'participate_interview', value: newValue})
      }
    }
  }
}
</script>

<style scoped>

</style>
